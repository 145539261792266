/* eslint-disable */
import React, { LegacyRef } from "react";

import projectData from "./projectData";

import s from "./Projects.module.scss";
import ProjectCard from "./components/ProjectCard/ProjectCard";

const Projects = ({
  id,
  projectsRef,
}: {
  id: string;
  projectsRef: LegacyRef<HTMLDivElement> | undefined;
}) => {
  return (
    <div ref={projectsRef} id={id} className={`${s.projectsContainer}`}>
      <h1 className={`${s.header}`}>Projects</h1>

      <div className={`${s.cardContainer}`}>
        {projectData.map((card) => {
          return <ProjectCard key={card.name} {...card} />;
        })}
      </div>
    </div>
  );
};

export default Projects;
