import React, { LegacyRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import s from "./About.module.scss";
import profilePic from "../../assets/profilePic.png";
import TechIcon from "./components/TechIcon";

const About = ({
  id,
  aboutRef,
}: {
  id: string;
  aboutRef: LegacyRef<HTMLDivElement> | undefined;
}): JSX.Element => {
  const [expanded, setExpanded] = React.useState<
    "panel1" | "panel2" | "panel3" | boolean
  >("panel1");

  const handleAccordionChange =
    (panel: "panel1" | "panel2" | "panel3") =>
    (_event: unknown, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div ref={aboutRef} className={`${s.aboutSectionContainer}`} id={id}>
      <div className={`${s.header}`}>
        <h1>About</h1>
      </div>
      <div className={`${s.aboutCols}`}>
        <div className={`${s.leftSection}`}>
          <img
            src={profilePic}
            className={`${s.profilePic}`}
            alt="LeAlan Carter Headshot with dashing beard"
          />
          <h2 className={`${s.myName}`}>LeAlan Carter</h2>
          <div className={`${s.subHeaderContainer}`}>
            <h4>Full-Time Web Developer</h4>
            <h4>Part-Time Musician</h4>
          </div>
          <div className={`${s.proficiencies}`}>
            <h3>Proficient In</h3>
            <div className={s.techContainer}>
              <TechIcon displayText="React" />
              <TechIcon displayText="HTML5" />
              <TechIcon displayText="CSS3" />
              <TechIcon displayText="Sass" />
              <TechIcon displayText="Javascript" />
              <TechIcon displayText="Node.js" />
              <TechIcon displayText="Cypress" />
            </div>
          </div>
        </div>
        <div className={`${s.rightSection}`}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleAccordionChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              What do I do?
            </AccordionSummary>
            <AccordionDetails>
              <p>
                I am a Senior Web Developer / Software Engineer, currently
                working out of my home in the Greater Birmingham area of
                Alabama. Sometimes I work on side projects or small apps in my
                spare time, but mostly work on enterprise applications for my
                employer.
              </p>
              <p>
                I am also involved with local React and Javascript meetup
                groups. There are also several Junior Developers that I mentor
                and assist with projects of their own.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleAccordionChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              Bio
            </AccordionSummary>
            <AccordionDetails>
              <p>
                My name is LeAlan Carter and I am 38 years old, married, and
                have 3 children. I live and work out of Helena, Alabama which is
                a suburb of Birmingham, Alabama.
              </p>

              <p>
                Since grade school, I have always been interested in
                programming. Over the years, I have accumulated a wide range of
                skills in web development and web design.
              </p>
              <p>
                Beginning in 2013 I started Bearded Squid doing small websites
                and web applications on a freelance or contract basis. A lot of
                my early sites were built with jQuery, HTML, and CSS. I also did
                several SquareSpace and WordPress projects when a CMS was
                needed.
              </p>
              <p>
                In 2016 I took a full-time remote Web Developer position with a
                startup called Remine LLC. As one of 3 developers on the project
                we created a SAAS Real Estate application for MLSes and
                Realtors. We built out many different versions of the
                application and full features in the three and a half years I
                was a developer there.
              </p>
              <p>
                My responsibilites with Remine included team and feature
                leading, front and back end development, and interviewing and
                screening new hires.
              </p>
              <p>
                In the spring of 2020 I started as a Senior Software Engineer as
                the the pricipal Frontend Engineer with Season Health. I have
                architected and built out the frontend application for both
                desktop and mobile using React with Typescript, Redux with
                Sagas, generated API types and functions from swagger docs,
                React testing library, CSS modules, and Cypress for end to end
                browser testing.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleAccordionChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              Hobbies
            </AccordionSummary>
            <AccordionDetails>
              <h5> Music </h5>
              <p>
                I have played guitar since I was 16 years old and played with a
                band called Creature Camp for over 5 years. One of our
                accomplishments was winning a national battle of the bands
                contest through Zzounds.com, an online musical equipment
                supplier.
              </p>
              <h5>Gaming</h5>
              <p>
                When I am not busy working or doing something with my children,
                I enjoy running gaming servers and doing the occasional gaming.
                Me and a friend built a gaming server that hosts servers for Ark
                Survival, Atlas, 7 Days to Die, and many other games, while also
                maintaining and working with the community for those servers.
              </p>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default About;
