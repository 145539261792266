import React, { useRef } from "react";
import s from "./App.module.scss";

import NavBar from "./components/NavBar/NavBar";
import Routes from "./Routes";

import withMobile from "./components/HOCs/withMobile";

interface AppProps {
  isMobile: boolean;
}

const App = ({ isMobile }: AppProps) => {
  const aboutRef = useRef<Element>();
  const projectsRef = useRef<Element>();
  const contactRef = useRef<Element>();
  const handleScrollTo = (element: "about" | "projects" | "contact") => {
    if (element === "about" && aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (element === "projects" && projectsRef.current) {
      projectsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (element === "contact" && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className={`${s.appContainer}`} id="scrollContainer">
      <NavBar isMobile={isMobile} handleScrollTo={handleScrollTo} />
      <Routes
        aboutRef={aboutRef}
        projectsRef={projectsRef}
        contactRef={contactRef}
      />
    </div>
  );
};
export default withMobile(App);
