import React from "react";

import s from "./TechIcon.module.scss";

export interface TechIconProps {
  displayText: string;
}

const TechIcon = ({ displayText }: TechIconProps): JSX.Element => {
  return (
    <div className={`${s.techIconContainer}`}>
      <p>{displayText}</p>
    </div>
  );
};

export default TechIcon;
