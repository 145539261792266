import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router-dom";

import { Menu } from "semantic-ui-react";
import { ArticlesResponseDTO, Article } from "./types";

import s from "./Articles.module.scss";

const Articles = (): JSX.Element => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [selectedArticleID, setSelectedArticleID] = useState<
    string | undefined
  >();
  const [articles, setArticles] = useState<Article[]>([]);
  const articleIdFromQuery = params.get("id");

  useEffect(() => {
    axios({
      method: "get",
      url: "https://www.googleapis.com/blogger/v3/blogs/750937790597107727/posts/?key=AIzaSyBo-xUMU5i_s97iQfnQ8PlyPxxqBCGExvA",
    }).then((response) => {
      const dataFromResponse: ArticlesResponseDTO =
        response as ArticlesResponseDTO;
      setArticles(dataFromResponse.data.items);
      setSelectedArticleID(
        articleIdFromQuery || dataFromResponse.data.items[0].id
      );
    });
  }, [articleIdFromQuery]);

  const handleArticleChange = (id: string) => {
    setSelectedArticleID(id);
  };

  const selectedArticle = articles.find((i) => i.id === selectedArticleID);

  return (
    <div className={`${s.articlesContainer}`}>
      <div className={`${s.header}`}>
        <h1>Articles</h1>
      </div>

      <div className={`${s.subSection}`}>
        {selectedArticle && (
          <>
            <div className={`${s.articleMenu}`}>
              <Menu vertical>
                {articles.map((article) => {
                  return (
                    <Menu.Item
                      key={article.id}
                      active={selectedArticle.id === article.id}
                      onClick={() => handleArticleChange(article.id)}
                    >
                      <h3>{article.title}</h3>
                      <p>{moment(article.published).format("MMMM Do YYYY")}</p>
                    </Menu.Item>
                  );
                })}
              </Menu>
            </div>
            <div className={`${s.articleContainer}`}>
              <div>{ReactHtmlParser(selectedArticle.content)}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Articles;
