import React from "react";
import { Switch, Route } from "react-router-dom";
import Articles from "./pages/Articles/Articles";
import Home from "./pages/Home/Home";
import Music from "./pages/Music/Music";

interface RouteProps {
  aboutRef: React.MutableRefObject<Element | undefined>;
  projectsRef: React.MutableRefObject<Element | undefined>;
  contactRef: React.MutableRefObject<Element | undefined>;
}

const Routes = (props: RouteProps): JSX.Element => (
  <main>
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <Home
            aboutRef={props.aboutRef}
            projectsRef={props.projectsRef}
            contactRef={props.contactRef}
          />
        )}
      />
      <Route path="/articles" component={Articles} />
      <Route path="/music" component={Music} />
    </Switch>
  </main>
);

export default Routes;
