import React from "react";

import s from "./MainHeader.module.scss";
import backgroundImage from "../../assets/backgroundImage.jpg";
import backgroundMobile from "../../assets/backgroundMobile.jpg";
import tentacleWaver from "../../assets/tentacleHoldingSign.svg";

interface MainHeaderProps {
  isMobile: boolean;
}

const MainHeader = ({ isMobile }: MainHeaderProps): JSX.Element => {
  return (
    <div
      className={s.headerContainer}
      style={{
        backgroundImage: ` url(${
          !isMobile ? backgroundImage : backgroundMobile
        })`,
      }}
    >
      <div className={`${s.headerText}`}>
        <h1 className={`${s.title}`}>BEARDED SQUID</h1>
        <h2>Web Development and Designs</h2>
      </div>
      {!isMobile && (
        <img
          src={tentacleWaver}
          className={`${s.tentacleSign}`}
          alt="welcome sign held by tentacle"
        />
      )}
    </div>
  );
};

export default MainHeader;
