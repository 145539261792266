import React, { LegacyRef } from "react";

import s from "./Contact.module.scss";

import backgroundImageContacts from "../../assets/backgroundImageContacts.gif";
import backgroundImageContactsMobile from "../../assets/backgroundImageContactsMobile.jpg";
import contactsHeaderBG from "../../assets/handshake.png";

import facebook from "../../assets/icons/facebook.png";
import twitter from "../../assets/icons/twitter.png";
import email from "../../assets/icons/email.png";
import github from "../../assets/icons/github.png";
import linkedin from "../../assets/icons/linkedin.png";

const Contact = ({
  id,
  contactRef,
  isMobile,
}: {
  id: string;
  contactRef: LegacyRef<HTMLDivElement> | undefined;
  isMobile: boolean;
}): JSX.Element => {
  return (
    <div
      className={`${s.contactContainer}`}
      id={id}
      ref={contactRef}
      style={{
        backgroundImage: ` url(${
          !isMobile ? backgroundImageContacts : backgroundImageContactsMobile
        })`,
      }}
    >
      <div
        className={`${s.contactsHeader}`}
        style={{
          backgroundImage: ` url(${contactsHeaderBG})`,
        }}
      >
        <h1>Contact Me</h1>
      </div>
      {isMobile && (
        <>
          <div className={`${s.mobileBubbleContainer}`}>
            <a
              className={`${s.linkBubble}`}
              href="https://www.facebook.com/beardedsquiddesigns/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="facebook link icon" />
            </a>
            <a
              className={`${s.linkBubble}`}
              href="https://twitter.com/BeardedSquid"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="twitter link icon" />
            </a>
            <a
              className={`${s.linkBubble}`}
              href="mailto:lealancarter@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={email} alt="email link icon" />
            </a>
            <a
              className={`${s.linkBubble}`}
              href="https://github.com/altimas"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={github} alt="github link icon" />
            </a>
            <a
              className={`${s.linkBubble}`}
              href="https://www.linkedin.com/pub/lealan-carter/a8/5a/4b4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="linkedin link icon" />
            </a>
          </div>
        </>
      )}
      {!isMobile && (
        <>
          <a
            className={`${s.linkBubble} ${s.facebook} ${s.notMobile}`}
            href="https://www.facebook.com/beardedsquiddesigns/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={facebook} alt="facebook link icon" />
          </a>
          <a
            className={`${s.linkBubble} ${s.twitter} ${s.notMobile}`}
            href="https://twitter.com/BeardedSquid"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitter} alt="twitter link icon" />
          </a>
          <a
            className={`${s.linkBubble} ${s.email} ${s.notMobile}`}
            href="mailto:lealancarter@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={email} alt="email link icon" />
          </a>
          <a
            className={`${s.linkBubble} ${s.github} ${s.notMobile}`}
            href="https://github.com/altimas"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={github} alt="github link icon" />
          </a>
          <a
            className={`${s.linkBubble} ${s.linkedin} ${s.notMobile}`}
            href="https://www.linkedin.com/pub/lealan-carter/a8/5a/4b4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="linkedin link icon" />
          </a>
        </>
      )}
    </div>
  );
};

export default Contact;
