import React, { useState } from "react";
import { Menu } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";

import s from "./NavBar.module.scss";
import logo from "../../assets/logo.png";
import hamburger from "../../assets/hamburger.svg";

interface NavBarProps {
  handleScrollTo: (element: "about" | "projects" | "contact") => void;
  isMobile: boolean;
}

const NavBar = ({ handleScrollTo, isMobile }: NavBarProps): JSX.Element => {
  const [mobileNavToggle, setMobileNavToggle] = useState(false);

  const toggleMobileNav = () => {
    setMobileNavToggle(!mobileNavToggle);
  };
  const location = useLocation();
  return (
    <ul className={`${s.navContainer}`}>
      <li>
        <div className={`${s.logoContainer}`}>
          <Link to="/">
            <img src={logo} className={`${s.logoIcon}`} alt="" />
          </Link>
        </div>
        {isMobile && (
          <button
            className={`${s.navToggle}`}
            onClick={() => toggleMobileNav()}
            type="button"
          >
            <img
              className={`${s.hamburger}`}
              src={hamburger}
              alt="mobile dropdown menu icon"
            />
          </button>
        )}
      </li>
      {(!isMobile || mobileNavToggle) && location.pathname === "/" && (
        <>
          <Menu.Item
            className={`${s.navLink}`}
            name="About"
            onClick={() => handleScrollTo("about")}
          >
            About
          </Menu.Item>
          <Menu.Item
            className={`${s.navLink}`}
            name="Projects"
            onClick={() => handleScrollTo("projects")}
          >
            Projects
          </Menu.Item>

          <Link className={`${s.navLink}`} to="/articles">
            Articles
          </Link>

          <Link className={`${s.navLink}`} to="/music">
            Music
          </Link>

          <Menu.Item
            className={`${s.navLink}`}
            name="Contact"
            onClick={() => handleScrollTo("contact")}
          >
            Contact
          </Menu.Item>
        </>
      )}
      {location.pathname !== "/" && (
        <Link className={`${s.navLink}`} to="/">
          {`< Back`}
        </Link>
      )}
    </ul>
  );
};

export default NavBar;
