/* eslint-disable */

import React from "react";
import AudioPlayer from "react-audioplaylist";

import cptSquid from "../../assets/CaptSquid.jpg";

import aLostExperimentAlbumCover from "../../assets/albums/ALostExperiment/ALostExperiment_AlbumCover.png";
import psychicWaves from "../../assets/albums/ALostExperiment/1 - Psychic Waves.mp3";
import vajraIntro from "../../assets/albums/ALostExperiment/2 - Vajra Intro.mp3";
import vajra from "../../assets/albums/ALostExperiment/3 - Vajra.mp3";
import thieving from "../../assets/albums/ALostExperiment/4 - Thieving.mp3";
import killer from "../../assets/albums/ALostExperiment/5 - Killer.mp3";
import iceman from "../../assets/albums/ALostExperiment/6 - Iceman.mp3";
import teethIntro from "../../assets/albums/ALostExperiment/7 - Teeth Intro.mp3";
import teeth from "../../assets/albums/ALostExperiment/8 - Teeth.mp3";
import phases from "../../assets/albums/ALostExperiment/9 - Phases.mp3";
import dayspringIntro from "../../assets/albums/ALostExperiment/10 - DaySpring Intro.mp3";
import dayspring from "../../assets/albums/ALostExperiment/11 - Dayspring.mp3";
import youFeel from "../../assets/albums/ALostExperiment/12 - You Feel.mp3";

import trainStationBlues from "../../assets/albums/DeerEmpire/DeerEmpire - TrainStationBlues.mp3";

import s from "./Music.module.scss";

const Music = () => {
  const aLostExperiment = {
    tracks: [
      {
        name: "Psychic Waves",
        artist: "Creature Camp",
        source: psychicWaves,
        color: "#F96300",
      },
      {
        name: "Vajra - Intro",
        artist: "Creature Camp",
        source: vajraIntro,
        color: "#E62F0B",
      },
      {
        name: "Vajra",
        artist: "Creature Camp",
        source: vajra,
        color: "#4C1749",
      },
      {
        name: "Thieving",
        artist: "Creature Camp",
        source: thieving,
        color: "#F96300",
      },
      {
        name: "Killer",
        artist: "Creature Camp",
        source: killer,
        color: "#E62F0B",
      },
      {
        name: "Iceman",
        artist: "Creature Camp",
        source: iceman,
        color: "#4C1749",
      },
      {
        name: "Teeth - Intro",
        artist: "Creature Camp",
        source: teethIntro,
        color: "#F96300",
      },
      {
        name: "Teeth",
        artist: "Creature Camp",
        source: teeth,
        color: "#E62F0B",
      },
      {
        name: "Phases",
        artist: "Creature Camp",
        source: phases,
        color: "#4C1749",
      },
      {
        name: "Dayspring - Intro",
        artist: "Creature Camp",
        source: dayspringIntro,
        color: "#F96300",
      },
      {
        name: "Dayspring",
        artist: "Creature Camp",
        source: dayspring,
        color: "#E62F0B",
      },
      {
        name: "You Feel",
        artist: "Creature Camp",
        source: youFeel,
        color: "#4C1749",
      },
    ],
  };

  const trainStationBluesAlbum = {
    tracks: [
      {
        name: "Train Station Blues",
        artist: "Deer Empire",
        source: trainStationBlues,
        color: "#F96300",
      },
    ],
  };

  return (
    <div className={`${s.musicPageContainer}`}>
      <div className={`${s.header}`}>
        <h1>Music</h1>
      </div>
      <div className={`${s.introSection}`}>
        <img
          src={cptSquid}
          alt="lealan carter in 1980s cruise captain attire"
        />
        <p className={`${s.introParagraph}`}>
          I grew up in a musical family and would spend a lot of time in my
          Grandfather, L. H. "Lucky" Carter's, basement watching him hand build
          and play pedal steel guitars. My Dad played guitar a little bit as
          well, and passed off one of his old cobbled together acoustics to me
          at an early age, but I really started to take more of an interest in
          music in my teens. I played guitar and bass in a myriad of different
          bands and church groups all the way up until I got married. I still
          practiced and played a lot on my own but didn't join another band
          until about 2012 when I started jamming with one of my brother's
          friend, Cory Anchors. From there we played a lot of cover shows and
          worked on original material and eventually became Creature Camp.
        </p>

        <p>
          As Creature Camp we won a national battle of the band with an online
          music retailer called Zzounds. We played tons of shows and had great
          times. We spent about 6-8 months in the studio and recorded our first
          full album called A Lost Experiment, shortly after the record was
          completed the band split after 5 years of playing together.
        </p>

        <p>
          Since leaving Creature Camp, I have mostly worked on my own original
          music and projects. I also have played in churches and lead worship
          services as well.
        </p>
      </div>
      <div className={`${s.albumContainer}`}>
        <h1 className={`${s.subHeader}`}>A Lost Experiment - Creature Camp</h1>
        <p>
          This was our first debut album as Creature Camp, produced by Daniel
          Farris (St. Vincent, The Polyphonic Spree) at Ol Elegante Studio. The
          Lost Experiment was the result of a two-and-a-half year songwriting
          process that saw initial ideas for the songs evolving based on live
          performances.
        </p>
        <h4>Artists:</h4>
        <p>
          Cory Anchors - Lead Vocals and Guitar <br /> LeAlan Carter - Lead
          Guitar and Backup Vocals <br /> Adam Huett - Bass and Backup Vocals{" "}
          <br /> Chris Hemphill - Synth and Keys <br /> David Hildebrand - Drums
        </p>
        <img
          src={aLostExperimentAlbumCover}
          alt="creature camp - a lost experiment album cover"
        />
        <AudioPlayer
          className={`${s.playerContainer}`}
          data={aLostExperiment}
        />
      </div>
      <div className={`${s.albumContainer}`}>
        <h1 className={`${s.subHeader}`}>Train Station Blues - Deer Empire</h1>
        <p>
          Before we were called Creature Camp, we played mostly cover songs and
          this original blues song called Train Station Blues as the band Deer
          Empire. This was recorded in the upstairs space of Avondale Brewery
          for a local artist feature event where each group played 2 songs.
        </p>
        <h4>Artists:</h4>
        <p>
          Cory Anchors - Lead Vocals and Guitar <br /> LeAlan Carter - Lead
          Guitar <br />
          Andrew Glass - Bass <br /> David Hildebrand - Drums
        </p>
        <AudioPlayer
          className={`${s.playerContainer}`}
          data={trainStationBluesAlbum}
        />
      </div>
    </div>
  );
};

export default Music;
