/* eslint-disable */
import React from "react";

const withMobile = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { width: 0 };
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth });
    }

    render() {
      const isMobile = this.state.width <= 768;
      const isTablet = this.state.width > 768 && this.state.width < 1024;
      return (
        <WrappedComponent
          isMobile={isMobile}
          isTablet={isTablet}
          {...this.props}
        />
      );
    }
  };
};

export default withMobile;
