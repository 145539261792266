/* eslint-disable */
import React from "react";
import { Card, Container } from "semantic-ui-react";

import s from "./ProjectCard.module.scss";

interface ProjectCardProps {
  backgroundIMG?: string;
  backgroundColor?: string;
  logo?: string;
  position?: string;
  synopsis?: string;
  tech?: string;
  name?: string;
  link?: string;
  codeLink?: string;
}

const ProjectCard = ({
  backgroundIMG = "",
  backgroundColor = "",
  logo = "",
  position = "",
  synopsis = "",
  tech = "",
  name = "",
  link = "",
  codeLink = "",
}: ProjectCardProps) => {
  return (
    <Card className={`${s.projectCardContainer}`}>
      <div
        className={`${s.cardHeader}`}
        style={{
          backgroundImage: ` url(${backgroundIMG ? backgroundIMG : ""})`,
          backgroundColor: `${backgroundColor ? backgroundColor : ""}`,
        }}
      >
        {logo && <img src={logo} alt="project card logo" />}
        <h5 className={`${s.cardName}`}>{name}</h5>
      </div>
      <Container text fluid className={`${s.cardText}`}>
        {position && <h4 className={`${s.position}`}>{position}</h4>}
        {synopsis && <p>{synopsis}</p>}
        {tech && (
          <div>
            <h5>Tech Used:</h5>
            <p>{tech}</p>
          </div>
        )}
        <div className={`${s.linksContainer}`}>
          {link && (
            <a href={link} target="_blank" rel="noreferrer">
              Website
            </a>
          )}
          {codeLink && (
            <a href={codeLink} target="_blank" rel="noreferrer">
              Code
            </a>
          )}
        </div>
      </Container>
    </Card>
  );
};

export default ProjectCard;
