import savageXBG from "../../assets/savageXFentyBG.gif";
import remineLogo from "../../assets/remineLogo.svg";
import lealancarterBG from "../../assets/lealancarterBG.jpg";
import seasonLogo from "../../assets/seasonLogo.svg";

const projectData = [
  {
    name: "Savage X Fenty",
    tech: "Next.Js, Ecommerce, Strapi, Typescript",
    logo: savageXBG,
    backgroundColor: "#000000",
    link: "http://www.savagex.com",
    codeLink: "",
    synopsis: "Rhianna's Lingerie and Loungewear enterprise eccommerce site.",
    position: "Senior Software Engineer",
  },
  {
    name: "Season Health",
    tech: "React/Redux, TypeScript, Java/Kotlin, Postgres/SQL, Cypress,",
    logo: seasonLogo,
    backgroundColor: "#fa7b52",
    link: "https://www.seasonhealth.com",
    codeLink: "",
    synopsis:
      "Digital Food Farmacy, dietitian supported mean planning with grocery ordering and delivery.",
    position: "Senior Software Engineer",
  },
  {
    name: "Remine",
    tech: "React/Redux, Node.js/TypeScript, Postgres/SQL",
    logo: remineLogo,
    backgroundColor: "rgba(52, 67, 94, 0.93)",
    link: "https://www.remine.com",
    codeLink: "",
    synopsis:
      "Enterpise Real Estate application, distributed through MLSes to Real Estate Agents, handling datasets for all properties and parcels nation-wide.",
    position: "Senior Software Engineer",
  },
  {
    name: "LeAlan Carter - Music",
    tech: "Next.js, Typescript, Spring animations",
    backgroundIMG: lealancarterBG,
    codeLink: "",
    synopsis: "Website for my singer-songwriter life.",
    position: "Singer Songwriter",
    link: "https://www.lealancarter.com",
  },
];

export default projectData;
