import React, { LegacyRef } from "react";

import MainHeader from "../../components/MainHeader/MainHeader";
import About from "../../components/About/About";
import Projects from "../../components/Projects/Projects";
import Contact from "../../components/Contact/Contact";

import withMobile from "../../components/HOCs/withMobile";

import s from "./Home.module.scss";

interface HomeProps {
  isMobile: boolean;
  aboutRef: LegacyRef<HTMLDivElement> | undefined;
  projectsRef: LegacyRef<HTMLDivElement> | undefined;
  contactRef: LegacyRef<HTMLDivElement> | undefined;
}
const Home = ({
  isMobile,
  aboutRef,
  projectsRef,
  contactRef,
}: HomeProps): JSX.Element => {
  return (
    <div className={`${s.container}`}>
      <MainHeader isMobile={isMobile} />
      <About aboutRef={aboutRef} id={encodeURIComponent("About")} />
      <Projects projectsRef={projectsRef} id={encodeURIComponent("Projects")} />
      <Contact
        contactRef={contactRef}
        id={encodeURIComponent("Contact")}
        isMobile={isMobile}
      />
    </div>
  );
};

export default withMobile(Home);
